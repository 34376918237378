import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaHome, FaFileImport  } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import { FaTrash } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";


export function Header(){
    const [isScrolled, setIsScrolled] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsActive(!isActive); // Alterna el estado entre true y false
    };
    const closeMenu = () => {
        setIsActive(false);
    };

    return (
        <div>
            <header className="site-header">
                <nav className={`navbar navbar-expand-lg nav-bottom-line center-brand static-nav ${isScrolled ? 'fixedmenu' : ''}`}>
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam-white.png" alt="logo" className="logo-default" />
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button" data-toggle="collapse" data-target="#xenav">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="xenav">
                            <ul className="navbar-nav mr-auto" id="container">
                                <li className="nav-item" style={{marginleft: '100px'}}>
                                    <a className="nav-link pagescroll" href="#home">Inicio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#about">¿Qué es?</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#bases">Bases</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/registro">Registro</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/login">Canjear</a>
                                </li> */}
                            </ul>
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#catalogo">Catálogo </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#entrenamientos">Entrenamientos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#contacto">Contacto</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#blog">Blog</a>
                                </li> */}
                                
                            </ul>
                        </div>
                    </div>
                    <a href="#" className="d-inline-block sidemenu_btn" id="sidemenu_toggle" onClick={toggleMenu}>
                        <span></span> <span></span> <span></span>
                    </a>
                </nav>

                {/* side menu */}
                <div className={isActive ? "side-menu side-menu-active" : "side-menu"}>
                    <div className="inner-wrapper">
                        <span className="btn-close" id="btn_sideNavClose" onClick={toggleMenu}><i></i><i></i></span>
                        <nav className="side-nav w-100">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#home">Inicio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#about">¿Qué es?</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#bases">Bases</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/registro">Registro</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link nav-link-canjea" href="/login">Canjear</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#catalogo">Catálogo </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#entrenamientos">Entrenamientos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#contacto">Contacto</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#reviews">Reviews</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#blog">Blog</a>
                                </li> */}
                                
                            </ul>
                        </nav>

                        <div className="side-footer w-100">
                            <p className="whitecolor">&copy; 2025 Be Focused un programa de Panduit.</p>
                        </div>
                    </div>
                </div>
            </header>

            <section className="page_content_main_slider" id="home">
                <div
                    id="rev_single_wrapper"
                    className="rev_slider_wrapper fullscreen-container rev-slider"
                    data-alias="inspiration-header"
                    data-source="gallery"
                    style={{ position: 'relative', height: '100vh' }} // Para que sea un contenedor a pantalla completa
                >
                    {/* Imagen de fondo */}
                    <img
                    src="./agency-studio/img/bckg-programa-be-focused-panduit-2025-2.jpg"
                    alt="background"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    />

                    {/* Contenedor para los elementos centrados */}
                    <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center', // Centrado vertical
                        alignItems: 'center',     // Centrado horizontal
                        textAlign: 'center',
                        color: '#fff',            // Color blanco para el texto
                        zIndex: 1,                // Para que el contenido esté encima de la imagen
                    }}
                    >
                    <h1 className="navy_blue raleway default_section_heading" style={{ color: '#fff' }}>
                        Bienvenid@s
                    </h1>
                    <p className="open_sans default_small_heading navy_blue" style={{ color: '#fff' }}>
                        Programa que reconoce la compra de soluciones Panduit
                    </p>
                    <a
                        href="/registro"
                        className="btn btn-yellow-black btn-rounded btn-large bg_before_navy"
                        style={{ lineHeight: '24px', marginTop: '20px' }}
                    >
                        Quiero Registrarme
                    </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export function HeaderDos(){
    const [isScrolled, setIsScrolled] = useState(true);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsActive(!isActive); // Alterna el estado entre true y false
    };
    const closeMenu = () => {
        setIsActive(false);
    };

    return (
        <div>
            <header className="site-header">
                <nav className={`navbar navbar-expand-lg nav-bottom-line center-brand static-nav ${isScrolled ? 'fixedmenu' : ''}`}>
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-default" />
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button" data-toggle="collapse" data-target="#xenav">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="xenav">
                            <ul className="navbar-nav mr-auto" id="container">
                                <li className="nav-item" style={{marginleft: '100px'}}>
                                    <a className="nav-link pagescroll" href="/">Inicio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#about">¿Qué es?</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#bases">Bases</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/registro">Registro</a>
                                </li>
                                
                            </ul>
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#catalogo">Catálogo </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#entrenamientos">Entrenamientos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#contacto">Contacto</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#blog">Blog</a>
                                </li> */}
                                
                            </ul>
                        </div>
                    </div>
                    <a href="#" className="d-inline-block sidemenu_btn" id="sidemenu_toggle" onClick={toggleMenu}>
                        <span></span> <span></span> <span></span>
                    </a>
                </nav>

                {/* side menu */}
                <div className={isActive ? "side-menu side-menu-active" : "side-menu"}>
                    <div className="inner-wrapper">
                        <span className="btn-close" id="btn_sideNavClose" onClick={toggleMenu}><i></i><i></i></span>
                        <nav className="side-nav w-100">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#home">Inicio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#about">¿Qué es?</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#bases">Bases</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/registro">Registro</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#catalogo">Catálogo </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#entrenamientos">Entrenamientos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="/#contacto">Contacto</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#reviews">Reviews</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href="#blog">Blog</a>
                                </li> */}
                                
                            </ul>
                        </nav>

                        <div className="side-footer w-100">
                            <p className="whitecolor">&copy; 2025 Be Focused un programa de Panduit.</p>
                        </div>
                    </div>
                </div>
            </header>

            <section className="page_content_main_slider" id="home">
                <div
                    id="rev_single_wrapper"
                    className="rev_slider_wrapper fullscreen-container rev-slider"
                    data-alias="inspiration-header"
                    data-source="gallery"
                >
                </div>
            </section>
        </div>
    )
}

export function HeaderAdmin(){

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    
    // Función para cerrar sesión
    const handleLogout = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/logout', {
                method: 'GET',
                credentials: 'include',  // Asegura que la cookie se envíe en la solicitud
            });

            if (response.ok) {
                // Redirigir al login después de que se haya cerrado sesión
                navigate('/login/admin');
            } else {
                console.error('Error al cerrar sesión:', await response.json());
            }
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };

    return (
        <header className="app-header flex items-center px-5 gap-4">
            <a href="index.html" className="logo-box">
                <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" className="h-6" alt="Small logo" />
            </a>

                <button id="button-toggle-menu" className="nav-link p-2 waves-effect me-auto d-none">
                </button>

                <div className="md:flex hidden items-center relative d-none">
                </div>

                <div className="relative d-none">
                </div>

            <div className="relative">
                <button data-fc-type="dropdown" data-fc-placement="bottom-end" type="button" className="nav-link flex items-center gap-2.5 waves-effect p-2 fc-dropdown open" onClick={toggleDropdown}>
                    <img src="/agency-studio/img/rocky.jpg" alt="user-image" className="rounded-full h-8 w-8" />
                    <span className="md:flex items-center hidden">
                        <span className="font-medium text-base">User.</span>
                        <i className='ph ph-chevron-down'></i>
                    </span>
                </button>
                <div className={`fc-dropdown fc-dropdown-open:opacity-100 ${ isOpen ? '' : 'hidden' } opacity-0 w-40 z-50 transition-[margin,opacity] duration-300 mt-2 bg-white shadow-lg border rounded-lg p-2 absolute open`} style={{left: '-55px', top: '35px'}}>
                    <a className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100" href="#" onClick={handleLogout}>
                        Log Out
                    </a>
                </div>
            </div>
        </header>
    )
}

export function SidebarAdmin() {
    const navigate = useNavigate();

    // Función para cerrar sesión
    const handleLogout = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/logout', {
                method: 'GET',
                credentials: 'include',  // Asegura que la cookie se envíe en la solicitud
            });

            if (response.ok) {
                // Redirigir al login después de que se haya cerrado sesión
                navigate('/login/admin');
            } else {
                console.error('Error al cerrar sesión:', await response.json());
            }
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };


    return (
        <div className="app-menu">
            <a href="/" className="logo-box">
                <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" style={{width:'70%'}} alt="logo" className="logo-scrolled" />
            </a>
            <div data-simplebar>
                <ul className="menu" data-fc-type="accordion">
                    <li className="menu-title">Men&uacute;</li>
                        <li className="menu-item">
                            <a href="/admin" className="menu-link waves-effect">
                                <span className="menu-icon"><FaHome /></span>
                                <span className="menu-text"> Panel </span>
                            </a>
                        </li>

                        <li className="menu-item">
                            <a href="/admin/usuarios" className="menu-link waves-effect">
                                <span className="menu-icon"><FaUser /></span>
                                <span className="menu-text"> Usuarios </span>
                            </a>
                        </li>

                    <li className="menu-title">Canjeo</li>
                    <li className="menu-title">Herramientas</li>

                    
                </ul>
            </div>
        </div>
    )
}




export function Footer() {
    return (
        <div>
            {/*  Footer Section */}
            <footer className="footer_section big_padding bg_navy">

                <div className="container">

                    <div className="footer_detail">

                        <ul className="footer_links text-center">

                            <li>

                                <a className="anchor_style_default facebook wow zoomInDown" href="https://www.facebook.com/panduit.latinamerica">

                                    <i className="text-center fab fa-facebook-f "></i>

                                </a>

                            </li>

                            <li>

                                <a className="anchor_style_default twitter wow zoomInUp" href="https://twitter.com/PanduitLATAM">

                                    <FaXTwitter />

                                </a>

                            </li>

                            <li>

                                <a className="anchor_style_default linkedin wow zoomInUp" href="https://www.linkedin.com/showcase/panduit-latin-america/">

                                    <i className="text-center fab fa-linkedin-in "></i>

                                </a>

                            </li>

                            <li>

                                <a className="anchor_style_default  pinterest wow zoomInDown" href="https://www.instagram.com/panduit.latam/">

                                    <i className="text-center fab fa-instagram"></i>

                                </a>

                            </li>

                        </ul>

                        <p className="text-center default_text open_sans white_color">&copy; 2025 Be Focused un programa de Panduit. </p>

                    </div>

                </div>

            </footer>
            {/*  /Footer Section */}
        </div>
    )
}

export default Header;
