import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { FaEye, FaHome, FaFileImport, FaEdit   } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import { FaTrash } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import { PiMicrosoftExcelLogoDuotone } from "react-icons/pi";

import { Modal, Button } from 'react-bootstrap';
import Pagination from '../components/Pagination';
import { HeaderAdmin, SidebarAdmin } from '../widgets/Widgets';

export function LoginAdmin() {
    const [correo, setCorreo] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    
    // Función para manejar el inicio de sesión
    const handleLogin = async (correo, password) => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/login/admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',  // Esto asegura que las cookies se envíen al backend
                body: JSON.stringify({ correo, password }),
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Inicio de sesión exitoso',
                    text: 'Redirigiendo al panel de administración',
                }).then(() => {
                    navigate('/admin');
                });
            } else {
                const data = await response.json();
                Swal.fire({
                    icon: 'error',
                    title: 'Error de autenticación',
                    text: data.message || 'Correo o contraseña incorrectos',
                });
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error del servidor',
                text: 'Hubo un problema al procesar tu solicitud. Intenta nuevamente más tarde.',
            });
        }
    };

    useEffect(() => {
        // Deshabilitar el estilo global del index.html
        const globalStyles = document.querySelector('link[href="/vendor/css/bundle.min.css"]');
        if (globalStyles) {
            globalStyles.disabled = true;
        }

        // Añadir estilos específicos de Admin
        const linkThemeCss = document.createElement('link');
        linkThemeCss.rel = 'stylesheet';
        linkThemeCss.href = '/assets/css/theme.min.css';
        linkThemeCss.type = 'text/css';
        document.head.appendChild(linkThemeCss);

        const linkIconsCss = document.createElement('link');
        linkIconsCss.rel = 'stylesheet';
        linkIconsCss.href = '/assets/css/icons.min.css';
        linkIconsCss.type = 'text/css';
        document.head.appendChild(linkIconsCss);

        // Limpiar estilos cuando el componente se desmonte
        return () => {
            if (globalStyles) {
                globalStyles.disabled = false;
            }
            // Remover estilos agregados dinámicamente
            document.head.removeChild(linkThemeCss);
            document.head.removeChild(linkIconsCss);
        };
    }, []);

    return (
        <div className="h-screen w-screen flex justify-center items-center" style={{background:'#243645'}}>
            <div className="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
                <div className="card overflow-hidden sm:rounded-md rounded-none">
                    <div className="px-6 py-8">
                        <a href="/" className="flex justify-center mb-8">
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" style={{width:'60%'}} alt="logo" className="logo-scrolled" />
                        </a>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleLogin(correo, password);
                        }}>
                        <div className="mb-4">
                            <input className="form-input" type="email" placeholder="Ingresa tu correo"
                            value={correo} 
                            onChange={(e) => setCorreo(e.target.value)} 
                            required 
                            />
                        </div>

                        <div className="mb-4">
                            <div className="flex items-center justify-between mb-2">
                            </div>
                            <input  className="form-input" type="password" placeholder="Ingresa tu contraseña" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required/>
                        </div>

                        <div className="flex justify-center mb-3">
                            <button className="btn w-full text-white" type="submit" style={{background:'#243645'}}> Ingresar </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Admin() {
    const [canjeos, setCanjeos] = useState([]);
    const [detalleCanjeo, setDetalleCanjeo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const canjeosPerPage = 10;
    const navigate = useNavigate();

    const checkAuth = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/admin', {
                method: 'GET',
                credentials: 'include',  // Asegura que las cookies se envíen en la solicitud
            });

            if (response.status === 401 || response.status === 403) {
                navigate('/login/admin');  // Redirige a la página de login si el token no es válido o no existe
            }
        } catch (error) {
            console.error('Error al verificar la autenticación:', error);
            navigate('/login/admin');
        }
    };

    useEffect(() => {
        checkAuth(); // Verifica la autenticación al cargar la página
    }, []);

    // Función para cerrar el modal
    const handleClose = () => {
        setShowModal(false); // Cambia el estado para cerrar el modal
    };

    // Declarar fetchCanjeos fuera del useEffect para que esté disponible en todo el componente
    const fetchCanjeos = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/admin/canjeos');
            const data = await response.json();
            setCanjeos(data);
        } catch (error) {
            console.error('Error al obtener los canjeos:', error);
        }
    };

    // useEffect para llamar a fetchCanjeos cuando el componente se monte
    useEffect(() => {
        fetchCanjeos(); // Llamar a fetchCanjeos cuando el componente se cargue
    }, []);

    // Función para abrir el modal y obtener los detalles del canjeo
    const fetchDetalleCanjeo = async (contId, fechaCanjeo) => {
        try {
            const response = await fetch(`https://system-befocused-production.up.railway.app/admin/canjeo-detalle/${contId}/${fechaCanjeo}`);
            const data = await response.json();

            if (response.ok) {
                // Mostrar el modal con los detalles del canjeo
                setDetalleCanjeo(data); // Guardar los datos en el estado
                setShowModal(true); // Abrir el modal
            } else {
                console.error('Error al obtener los detalles del canjeo:', data.message);
            }
        } catch (error) {
            console.error('Error al obtener el detalle del canjeo:', error);
        }
    };

    const eliminarCanjeo = (fechaAcumulacion) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción es irreversible. El canje será eliminado permanentemente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`https://system-befocused-production.up.railway.app/admin/eliminar-canjeo/${fechaAcumulacion}`, {
                        method: 'DELETE'
                    });
                    const data = await response.json();
                    if (response.ok) {
                        Swal.fire('Eliminado', 'El canje ha sido eliminado correctamente.', 'success');
                        // Llamar a fetchCanjeos para actualizar la lista después de eliminar
                        fetchCanjeos();  // Asegúrate de que fetchCanjeos está correctamente definido
                    } else {
                        Swal.fire('Error', data.message || 'No se pudo eliminar el canje', 'error');
                    }
                } catch (error) {
                    console.error('Error al eliminar el canje:', error);
                    Swal.fire('Error', 'Hubo un problema al intentar eliminar el canje.', 'error');
                }
            }
        });
    };

    // Calcular el índice de inicio y fin de los canjeos para la página actual
    const indexOfLastCanjeo = currentPage * canjeosPerPage;
    const indexOfFirstCanjeo = indexOfLastCanjeo - canjeosPerPage;
    const currentCanjeos = canjeos.slice(indexOfFirstCanjeo, indexOfLastCanjeo);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        // Deshabilitar el estilo global del index.html
        const globalStyles = document.querySelector('link[href="/vendor/css/bundle.min.css"]');
        if (globalStyles) {
            globalStyles.disabled = true;
        }

        // Añadir estilos específicos de Admin
        const linkThemeCss = document.createElement('link');
        linkThemeCss.rel = 'stylesheet';
        linkThemeCss.href = '/assets/css/theme.min.css';
        linkThemeCss.type = 'text/css';
        document.head.appendChild(linkThemeCss);

        const linkIconsCss = document.createElement('link');
        linkIconsCss.rel = 'stylesheet';
        linkIconsCss.href = '/assets/css/icons.min.css';
        linkIconsCss.type = 'text/css';
        document.head.appendChild(linkIconsCss);

        // Limpiar estilos cuando el componente se desmonte
        return () => {
            if (globalStyles) {
                globalStyles.disabled = false;
            }
            // Remover estilos agregados dinámicamente
            document.head.removeChild(linkThemeCss);
            document.head.removeChild(linkIconsCss);
        };
    }, []);

    return (
        <div className='app-wrapper'>
            <SidebarAdmin/>
            <div className='app-content'>
                <HeaderAdmin/>
                <main className="p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h4 className="text-slate-900 text-lg font-medium">Panel</h4>
                    </div>
                    <div className="grid xl:grid-cols-1 md:grid-cols-1 gap-6 mb-12">
                        <div className="col-xl-12 col-md-12">
                            <div className="card">
                                <div className="p-5">
                                    <div className="card bg-white overflow-hidden">
                                        <div className="card-header">
                                            <h4 className="card-title">Listas de Canje</h4>
                                        </div>
                                        <div>
                                            <div className="overflow-x-auto">
                                                <div className="min-w-full inline-block align-middle">
                                                    <div className="overflow-hidden">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500"># Canje</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Distribuidor</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Canal</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Nombre</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Puntos Canjeados</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Fecha</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Acción</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200">
                                                            {currentCanjeos.map((canjeo, index) => (
                                                                <tr className="hover:bg-gray-100" key={index}>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" style={{ textAlign: 'center' }}>{index + 1}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{canjeo.nombre_distribuidor}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{canjeo.nombre_canal}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{canjeo.nombre_usuario}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{Math.abs(canjeo.total_puntos_canjeados)}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{new Date(canjeo.fecha_canjeo).toLocaleString('es-MX')}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                                            <a 
                                                                                className="text-primary hover:text-sky-700" 
                                                                                href="#"
                                                                                onClick={() => {
                                                                                    const fecha = new Date(canjeo.fecha_canjeo);
                                                                                    const fechaFormateada = fecha.getFullYear() + '-' +
                                                                                        ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
                                                                                        ('0' + fecha.getDate()).slice(-2) + ' ' +
                                                                                        ('0' + fecha.getHours()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getMinutes()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getSeconds()).slice(-2);

                                                                                    fetchDetalleCanjeo(canjeo.CONT_ID, fechaFormateada);
                                                                                }}
                                                                                style={{ fontSize: '1.5rem' }}
                                                                            >
                                                                                <FaEye />
                                                                            </a>
                                                                            <a 
                                                                                className="text-primary hover:text-sky-700" 
                                                                                href="#" 
                                                                                onClick={() => {
                                                                                    const fecha = new Date(canjeo.fecha_canjeo);
                                                                                    const fechaFormateada = fecha.getFullYear() + '-' +
                                                                                        ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
                                                                                        ('0' + fecha.getDate()).slice(-2) + ' ' +
                                                                                        ('0' + fecha.getHours()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getMinutes()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getSeconds()).slice(-2);
                                                                                    
                                                                                    eliminarCanjeo(fechaFormateada);
                                                                                }} 
                                                                                style={{ fontSize: '1.2rem' }}
                                                                            >
                                                                                <FaTrash />
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    {/* Componente de paginación */}
                                                    <Pagination 
                                                        canjeosPerPage={canjeosPerPage} 
                                                        totalCanjeos={canjeos.length} 
                                                        paginate={paginate} 
                                                        currentPage={currentPage}
                                                    />
                                                    <Modal show={showModal} onHide={handleClose}>
                                                        <Modal.Header style={{display:'flex', justifyContent:'space-between'}}>
                                                            <Modal.Title style={{ color: '#000', textAlign:'center', fontSize:'25px', marginBottom:'10px' }}>Detalle del Canjeo</Modal.Title>
                                                            <IoMdClose onClick={handleClose} style={{cursor:'pointer'}}/>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            {detalleCanjeo ? (
                                                                <div className="p-4 overflow-y-auto" style={{paddingTop:'0px', paddingLeft:'0px'}}>
                                                                    <h5 style={{marginBottom:'5px'}}>Información del Solicitante</h5>
                                                                    <p><strong>Nombre del Solicitante:</strong> {detalleCanjeo.direccion.DOM_nombre_solicitante}</p>
                                                                    <p><strong>Correo Electrónico:</strong> {detalleCanjeo.direccion.DOM_correo}</p>
                                                                    <p><strong>Teléfono:</strong> {detalleCanjeo.direccion.DOM_telefono}</p>
                                                                    <p><strong>Dirección:</strong> {detalleCanjeo.direccion.direccion}</p>
                                                                    <p><strong>Entre Calles:</strong> {detalleCanjeo.direccion.DOM_entre_calles}</p>
                                                                    <p><strong>Notas:</strong> {detalleCanjeo.direccion.DOM_notas || 'N/A'}</p>
                                                                    <hr style={{marginBottom:'10px'}}></hr>
                                                                    <h5>Productos Canjeados</h5>
                                                                    <table className="min-w-full divide-y divide-gray-200">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope='col' className='px-6 py-3 text-start text-sm text-gray-500'>Producto</th>
                                                                                <th scope='col' className='px-6 py-3 text-sm text-gray-500'>Cantidad</th>
                                                                                <th scope='col' className='px-6 py-3 text-sm text-gray-500'>Puntos Canjeados</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='divide-y divide-gray-200'>
                                                                            {detalleCanjeo.productos.map((producto, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{producto.PROD_nombre}</td>
                                                                                    <td style={{textAlign:'center'}}>{producto.PP_cantidad}</td>
                                                                                    <td style={{textAlign:'center'}}>{producto.PP_canjeados}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <br></br>

                                                                    <h5>Total Puntos Canjeados: {detalleCanjeo.totalPuntos}</h5>
                                                                </div>
                                                            ) : (
                                                                <p>Cargando detalles del canje...</p>
                                                            )}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <div className="text-right w-100">
                                                                <Button 
                                                                    className="btn rounded-full border border-info text-info hover:bg-info hover:text-white ml-auto" 
                                                                    onClick={handleClose}
                                                                >
                                                                    Cerrar
                                                                </Button>
                                                            </div>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export function AdminUsers(){
    const [usuarios, setUsuarios] = useState([]);
    const [exportando, setExportando] = useState(false);

    useEffect(() => {
        // Deshabilitar el estilo global del index.html
        const globalStyles = document.querySelector('link[href="/vendor/css/bundle.min.css"]');
        if (globalStyles) {
            globalStyles.disabled = true;
        }

        // Añadir estilos específicos de Admin
        const linkThemeCss = document.createElement('link');
        linkThemeCss.rel = 'stylesheet';
        linkThemeCss.href = '/assets/css/theme.min.css';
        linkThemeCss.type = 'text/css';
        document.head.appendChild(linkThemeCss);

        const linkIconsCss = document.createElement('link');
        linkIconsCss.rel = 'stylesheet';
        linkIconsCss.href = '/assets/css/icons.min.css';
        linkIconsCss.type = 'text/css';
        document.head.appendChild(linkIconsCss);

        // Limpiar estilos cuando el componente se desmonte
        return () => {
            if (globalStyles) {
                globalStyles.disabled = false;
            }
            // Remover estilos agregados dinámicamente
            document.head.removeChild(linkThemeCss);
            document.head.removeChild(linkIconsCss);
        };
    }, []);

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await fetch('https://system-befocused-production.up.railway.app/admin/usuarios');
                const data = await response.json();
                setUsuarios(data);
            } catch (error) {
                console.error('Error al obtener usuarios:', error);
            }
        };

        fetchUsuarios();
    }, []);

    const getFechaArchivo = () => {
        const fecha = new Date();
        const dia = String(fecha.getDate()).padStart(2, '0');
        const mes = fecha.toLocaleString('es-MX', { month: 'short' }).toUpperCase();
        return `${dia}-${mes}`;
    };
        

    const exportToExcel = async () => {
        setExportando(true);
    
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Usuarios');
    
        // 1. Agregar encabezados
        worksheet.columns = [
            { header: '#', key: 'id', width: 5 },
            { header: 'Nombre completo', key: 'nombre', width: 25 },
            { header: 'Empresa', key: 'empresa', width: 20 },
            { header: 'Cargo', key: 'cargo', width: 20 },
            { header: 'Teléfono', key: 'telefono', width: 15 },
            { header: 'Correo', key: 'correo', width: 30 },
            { header: 'Emails adicionales', key: 'emails', width: 30 },
            { header: 'Fecha de registro', key: 'fecha', width: 22 },
        ];
    
        // 2. Insertar filas
        usuarios.forEach((u, index) => {
            worksheet.addRow({
                id: index + 1,
                nombre: `${u.USU_nombre} ${u.USU_apellido}`,
                empresa: u.USU_company,
                cargo: u.USU_cargo === 'otro' ? u.USU_cargo_otro : u.USU_cargo,
                telefono: u.USU_telefono,
                correo: u.USU_email,
                emails: u.USU_emails || 'No hay emails adicionales',
                fecha: new Date(u.USU_actuacion).toLocaleString('sv-SE').replace('T', ' ')
            });
        });
    
        // 3. Estilos del header
        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '002256' }
            };
            cell.font = {
                color: { argb: 'FFFFFF' },
                bold: true
            };
            cell.alignment = { horizontal: 'left' };
        });
    
        // 4. Generar y guardar archivo
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Lista-Usuarios-BFMX-${getFechaArchivo()}.xlsx`);
    
        setExportando(false);
    };

    return(
        <div className='app-wrapper'>
            <SidebarAdmin/>
            <div className='app-content'>
                <HeaderAdmin/>
                <main className="p-6">
                    {/* <div className="flex justify-between items-center mb-6">
                        <h4 className="text-slate-900 text-lg font-medium">Usuarios</h4>
                    </div> */}

                    <div className="grid gap-6 mt-8">
                        <div className="card bg-white overflow-hidden">
                            <div className="card-header flex items-center justify-between">
                                <h4 className="card-title">Lista de usuarios</h4>
                                <button type="button" className="btn border-success text-success hover:bg-success hover:text-white" onClick={exportToExcel} disabled={exportando}>
                                    <PiMicrosoftExcelLogoDuotone style={{fontSize:'22px'}} /> 
                                    {exportando ? ' Exportando...' : ' Exportar Excel'}
                                </button>
                            </div>
                            <div>
                                <div className="overflow-x-auto">
                                    <div className="min-w-full inline-block align-middle">
                                        <div className="overflow-hidden">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Usuario</th>
                                                        <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Compañia</th>
                                                        <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Cargo</th>
                                                        <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Notas</th>
                                                        <th scope="col" className="px-6 py-3 text-sm text-gray-500">Acción</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {usuarios.map((u) => (
                                                    <tr  key={u.USU_id} className="odd:bg-white even:bg-gray-100 hover:bg-gray-100">
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                            <div className="flex items-center gap-3">
                                                                <FaUserTie className="text-xl text-gray-600" />
                                                                <div>
                                                                    <div className="font-medium">{u.USU_nombre} {u.USU_apellido}</div>
                                                                    <div className="text-xs text-gray-500"><a href={`mailto:${u.USU_email}`}>{u.USU_email}</a></div>
                                                                    <div className="text-xs text-gray-500"><a href={`tel:${u.USU_telefono}`}>{u.USU_telefono}</a></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{u.USU_company} </td>
                                                        <td className="px-6 py-4 text-sm text-gray-800">{u.USU_cargo === 'otro' ? u.USU_cargo_otro : u.USU_cargo}</td>
                                                        <td className="px-6 py-4 text-sm text-gray-800">
                                                            {u.USU_emails ? (
                                                                u.USU_emails
                                                            ) : (
                                                                <em className="text-gray-400">No hay emails adicionales.</em>
                                                            )}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                                            <div className="flex justify-end gap-x-2">
                                                                <button className="btn bg-primary/25 text-primary hover:bg-primary hover:text-white"><FaEye /></button>
                                                                <button className="btn bg-warning/25 text-warning hover:bg-warning hover:text-white"><FaEdit /></button>
                                                                <button className="btn bg-danger/25 text-danger hover:bg-danger hover:text-white"><FaTrash /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export function ExportAdmin() {
    
    const navigate = useNavigate();

    const checkAuth = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/admin', {
                method: 'GET',
                credentials: 'include',  // Asegura que las cookies se envíen en la solicitud
            });

            if (response.status === 401 || response.status === 403) {
                navigate('/login/admin');  // Redirige a la página de login si el token no es válido o no existe
            }
        } catch (error) {
            console.error('Error al verificar la autenticación:', error);
            navigate('/login/admin');
        }
    };

    useEffect(() => {
        checkAuth(); // Verifica la autenticación al cargar la página
    }, []);

    // Función para cerrar sesión
    const handleLogout = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/logout', {
                method: 'GET',
                credentials: 'include',  // Asegura que la cookie se envíe en la solicitud
            });

            if (response.ok) {
                // Redirigir al login después de que se haya cerrado sesión
                navigate('/login/admin');
            } else {
                console.error('Error al cerrar sesión:', await response.json());
            }
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };

    useEffect(() => {
        // Deshabilitar el estilo global del index.html
        const globalStyles = document.querySelector('link[href="/vendor/css/bundle.min.css"]');
        if (globalStyles) {
            globalStyles.disabled = true;
        }

        // Añadir estilos específicos de Admin
        const linkThemeCss = document.createElement('link');
        linkThemeCss.rel = 'stylesheet';
        linkThemeCss.href = '/assets/css/theme.min.css';
        linkThemeCss.type = 'text/css';
        document.head.appendChild(linkThemeCss);

        const linkIconsCss = document.createElement('link');
        linkIconsCss.rel = 'stylesheet';
        linkIconsCss.href = '/assets/css/icons.min.css';
        linkIconsCss.type = 'text/css';
        document.head.appendChild(linkIconsCss);

        // Limpiar estilos cuando el componente se desmonte
        return () => {
            if (globalStyles) {
                globalStyles.disabled = false;
            }
            // Remover estilos agregados dinámicamente
            document.head.removeChild(linkThemeCss);
            document.head.removeChild(linkIconsCss);
        };
    }, []);

    return(
        <div className='app-wrapper'>
            <div className="app-menu">
                <a href="/" className="logo-box">
                    <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" style={{width:'70%'}} alt="logo" className="logo-scrolled" />
                </a>
                <div data-simplebar>
                    <ul className="menu" data-fc-type="accordion">
                        <li className="menu-title">Menú</li>

                        <li className="menu-item">
                            <a href="/admin" className="menu-link waves-effect">
                                <span className="menu-icon"><FaHome /></span>
                                <span className="menu-text"> Panel </span>
                            </a>
                        </li>

                        <li className="menu-item">
                            <a href="/admin/importar" className="menu-link waves-effect">
                                <span className="menu-icon"><FaFileImport /></span>
                                <span className="menu-text"> Importar </span>
                            </a>
                        </li>

                        <li className="menu-item">
                            <a href="#" onClick={handleLogout} className="menu-link waves-effect">
                                <span className="menu-icon"><IoLogOut /></span>
                                <span className="menu-text"> Cerrar Sesión </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='app-content'>
                <main className="p-6"></main>
            </div>
        </div>
    )
}



export default Admin