import React from 'react'
import { HeaderDos } from '../widgets/Widgets'
import Slider from 'react-slick';
import { Webinar2024, PanduitWeek2024 } from './Webinar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function SectionWebinar2024() {
    const settings = {
        dots: false,             // Muestra puntos de navegación
        infinite: true,         // Desplazamiento infinito
        speed: 500,             // Velocidad del scroll
        slidesToShow: 3,        // Número de elementos visibles
        slidesToScroll: 1,      // Desplazar un slide a la vez
        autoplay: true,         // Habilita el desplazamiento automático
        autoplaySpeed: 2000,    // Velocidad del desplazamiento automático (ms)
        responsive: [           // Responsividad para que funcione en diferentes tamaños de pantalla
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <HeaderDos />
            <section className="pricing_section big_padding bg_grey" id="entrenamientos" style={{marginTop: '100px'}}>

                <div className="container">

                    <div className="pricing_table_section">
                        <p className="default_text raleway text-center default_small_heading blue_color font_200">Webinar's</p>

                        <h2 className="default_section_heading text-center">

                                        <span className="font_200"> Nuestros</span> Entrenamientos

                        </h2>

                        <hr className="default_divider default_divider_blue default_divider_big" />

                        {/* Entrenamientos Panduit */}
                        <Webinar2024/>
                        

                    </div>

                </div>

            </section>

            <section class="blog_section big_padding" id="entrenamientos" style={{paddingtop: '0px'}}>

                <div class="container">
                    <h2 class="default_section_heading text-center">
                        <span class="font_200">
                            Entrenamientos
                        </span>
                        Panduit 
                        <span class="font_200">
                        Week 
                        </span>
                    </h2>
                    <hr class="default_divider default_divider_blue default_divider_big" />
                        {/* Seccion Panduit Week */}
                        <PanduitWeek2024/>
                </div>
            </section>
        </div>
    )
}