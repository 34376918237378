import React from 'react';
import Header from '../widgets/Widgets';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Webinar2024 } from './Webinar';
import { FaWhatsapp } from "react-icons/fa";
import { PanduitWeek2024 } from './Webinar';


function Home() {
    const settings = {
        dots: false,             // Muestra puntos de navegación
        infinite: true,         // Desplazamiento infinito
        speed: 500,             // Velocidad del scroll
        slidesToShow: 4,        // Número de elementos visibles
        slidesToScroll: 1,      // Desplazar un slide a la vez
        autoplay: true,         // Habilita el desplazamiento automático
        autoplaySpeed: 2000,    // Velocidad del desplazamiento automático (ms)
        responsive: [           // Responsividad para que funcione en diferentes tamaños de pantalla
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Header />
            <section className="page_content_parent_section">
                {/* <a href="https://api.whatsapp.com/send?phone=5519486139" className="btn-wsp" target="_blank">
                    <FaWhatsapp />
                </a> */}
                
                <section className="combined_skills_section big_padding" id="about">
                    <section className="skill_section" >

                        <div className="container">

                            <div className="skill_section_inner">

                                <p className="default_text raleway text-center default_small_heading blue_color font_200">BE FOCUSED</p>

                                <h2 className="default_section_heading text-center">

                                        <span className="font_200">

                                            Programa para  

                                        </span>

                                        <span style={{marginLeft:'10px'}}>Integradores </span><span style={{color:' #00aa89', marginLeft:'10px'}}>Certificados</span>

                                </h2>

                                <hr className="default_divider default_divider_blue default_divider_big" />

                            </div>

                        </div>

                    </section>

                    {/* /Skill Section */}

                    <section className="circle_pie_section">

                        <div className="container">

                            <div className="row">

                                <div className="col-sm-12">

                                    <div className="expertise_section_inner">

                                        <div className="row">

                                            <div className="col-md-4">

                                                <div className="expertise_block text-center margin_after_tab wow slideInLeft">

                                                    <h3 className="small_heading_navy text-center raleway h3_pink font_400"><span className="font_600 pink_color">01.</span>Vigencia</h3>

                                                    <p className="default_text text-center open_sans default_text_light">

                                                    Este programa estará vigente de mayo a octubre de 2025. Permite a los partners inscritos acumular <strong> PanPoints</strong>, de acuerdo a la venta obtenida cada mes.

                                                    </p>

                                                </div>

                                            </div>

                                            <div className="col-md-4">

                                                <div className="expertise_block text-center margin_after_tab wow fadeInUp">

                                                    <h3 className="small_heading_navy text-center raleway h3_pink font_400"><span className="font_600 pink_color">02.</span> Entrenamientos</h3>

                                                    <p className="default_text text-center open_sans default_text_light">

                                                    Mes a mes tendremos <strong>entrenamientos digitales </strong> para que conozcan y se capaciten en nuestras soluciones. La asistencia también te da <strong> PanPoints</strong>.

                                                    </p>

                                                </div>

                                            </div>

                                            <div className="col-md-4">

                                                <div className="expertise_block text-center wow slideInRight">

                                                    <h3 className="small_heading_navy text-center raleway h3_pink font_400"><span className="font_600 pink_color">03.</span> Premios</h3>

                                                    <p className="default_text text-center open_sans default_text_light">

                                                    Con el acumulado de PanPoints podrá <strong> obtener diferentes premios</strong>, éstos pueden ser canjeados al final del programa. Consulte el <a style={{color:' #0074b8'}} href="/catalogo">catálogo</a>.

                                                    
                                                    </p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                            <br />
                            <div className="pricing_button" style={{display: 'flex', justifyContent: 'center'}} >
                                <a href="/registro" className="btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy" style={{width: '300px', alignitems: 'center'}}>Registro</a>
                            </div>
                            <p className="default_text text-center open_sans default_text_light" style={{textAlign: 'center', justifyContent: 'center', display: 'flex', fontSize: '12px', marginTop: '10px'}}>Regístrate y obtén 5 PanPoints. Válido hasta el 22 de junio.</p>


                            
                    </section>

                </section> 

                <section className="half_section_main half_section_right bg_grey" id="bases">

                    <div className="half_section_picture d-none d-md-block hidden-sm hidden-xs"></div>

                    <div className="container container_big">

                        <div className="row">

                            <div className="col-md-5 col-16">

                                <div className="side_section_text big_padding">

                                    <p className="default_small_heading raleway blue_color font_200">Cómo funciona</p>

                                    <h3 className="default_section_heading raleway navy_blue"><span className="font_200"> Bases </span> Be Focused <span className="font_200">  </span></h3>

                                    <hr className="default_divider default_divider_blue " style={{marginleft: '0'}} />

                                    <ul className="default_text_light open_sans">
                                        <li><strong>La participación en este programa es  bajo invitación </strong> por parte de los distribuidores participantes. Al integrador que acepte tal invitación se le considerarán únicamente las compras de  <strong>nuevos productos Panduit </strong>realizadas con dicho distribuidor, considerando:</li><br/>
                                        
                                        <br />
                                        
                                        <ul>
                                            <a class="link-azul" target="_blank" href="https://www.panduit.com/content/dam/panduit/es/products/adm-uplad/2022/fieldcore/FieldCord_EN_Hoja-de-Especificaciones_CFCTC6U_COSP512--WW-SPA_1-2022.pdf">
                                                <li>FieldCord™</li>
                                            </a>
                                            
                                            <a class="link-azul" target="_blank"  href="https://mkt.panduit.com/FlexFusion-LATAM.html">
                                                <li>Gabinetes FlexFusion</li>
                                            </a>

                                            <li>
                                                <a class="link-azul" target="_blank"  href="https://www.panduit.com/latam/es/products/power-distribution-environmental-connectivity-hardware.html">Soluciones de Energía:</a>
                                                <a class="link-azul" target="_blank"  href="https://mkt.panduit.com/Power-Distribution-Units-LP-LATAM.html"> PDUS </a> y
                                                <a class="link-azul" target="_blank"  href="https://mkt.panduit.com/smartzoneups-LATAM.html">UPS
                                                </a>
                                            </li>

                                            <a class="link-azul" target="_blank"  href="https://mkt.panduit.com/WireBasket-LATAM.html"><li>Wire Basket</li></a>
                                            <a class="link-azul" target="_blank"  href="https://www.panduit.com/content/dam/panduit/es/images/landing-pages/atlona/Multi-Soluciones-Audio-y-Video_AV_Flyer_06-2024.pdf"><li>Soluciones de Audio y Video (Atlona)</li></a>
                                            <li>Soluciones eléctricas: 
                                                <a class="link-azul" target="_blank"  href="https://www.panduit.com/latam/es/products/grounding-bonding.html">Unión y Puesta a Tierra,</a>
                                                <a class="link-azul" target="_blank"  href="https://www.panduit.com/latam/es/products/signs-labels-identification.html"> Etiquetas, Impresoras,</a>
                                                <a class="link-azul" target="_blank"  href="https://www.panduit.com/latam/es/products/contractor-products.html">Cintillos y Velcros</a>
                                            </li>
                                        </ul>
                                    
                                        <div className="pricing_button" style={{display: 'flex', justifyContent:'center'}}>
                                            <a className="btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10" style={{width: '300px'}} id="descargaEnlace" href="/assets/docs/catalogo-productos-participantes-be-focused-2024.pdf" target="_blank" rel="noopener noreferrer"> Productos Participantes</a>
                                        </div>

                                        <br />

                                        <li>La compañía participante recibirá <strong>el .8% de su compra mensual en PanPoints.</strong> Cada PanPoint tiene un valor de 1 dólar, ejemplo:  $1,000 usd  x .008 = $8 usd  en premios</li><br />

                                        <li>Todas las compras de productos nuevos Panduit por parte del integrador en el mes vigente suman.</li><br/>

                                        <li>Los integradores pueden canjear sus <strong>PanPoints </strong>mensualmente o acumularlos para obtener el premio de su elección al cierre del programa. En tal caso, el integrador, consultará con su distribuidor, los puntos acumulados. </li><br/>
                                        </ul>
                                    

                                    
                                </div> 

                            </div>

                        </div>

                    </div>

                </section>

                <section className="half_section_main bg_grey" id="canjea">

                    <div className="half_section_picture d-none d-lg-block hidden-sm hidden-xs"></div>
            
                    <div className="container container_big">
            
                        <div className="row">
            
                            <div className="col-lg-6 offset-lg-6 col-12 offset-0">
            
                                <div className="side_section_text big_padding">
            
                                    <p className="default_small_heading raleway blue_color font_200">Be Focused  de Panduit</p>
            
                                    <h3 className="default_section_heading raleway navy_blue">Cómo canjear  <span className="font_200"> los PanPoints </span></h3>
            
                                    <hr className="default_divider default_divider_blue " style={{marginleft: '0'}} />
            
                                    <ul className="default_text_light open_sans">
                                        <li>Una vez que el integrador conozca la cantidad de puntos obtenidos, el podrá consultar el catálogo 2025 en esta página web y elegirá su premio.</li><br/>
            
                                        <li>Para canjearlo, deberá llenar un <strong> formulario </strong> y dar aviso al distribuidor.</li><br/>
            
                                        <li><strong>El premio llegará en un periodo de 2 semanas aproximadamente.</strong></li><br/>
            
                                        <li>Las imágenes de los premios son <u> ilustrativas</u> y <i> podrán ser modificadas de acuerdo a la disponibilidad de inventario y modelos disponibles al momento del canje.</i> Los productos pueden ser reemplazados por otros similares en características, marca y calidad.</li><br/>

                                        <li><strong style={{color: '#00aa89'}}>La vigencia del programa es de mayo a octubre de 2025.</strong></li><br/>
                                    </ul>

                                    {/* <div className="pricing_button" style={{display: 'flex', justifycontent: 'center'}}>
                                        <a href="/login" className="btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy" style={{width: '300px', alignitems: 'center'}}>Canjear</a>
                    
                                    </div> */}
            
                                </div>
            
                            </div>
            
                        </div>
            
                    </div>
            
                </section>

                <section className="team_section big_padding text-center " id="catalogo">
                    <div className="container">

                        <p className="raleway default_small_heading blue_color font_200">Catálogo de </p>

                        <div className="col-md-12">

                            <h2 className="default_section_heading text-center">

                                        <span className="font_200" style={{marginRight:'5px'}}>Premios </span>2025

                            </h2>

                            <hr className="default_divider default_divider_blue default_divider_big" />

                            <div className="swiper-container team_slider">
                                <Slider {...settings}>
                                    {/* Primer slide */}
                                    <div className="swiper-slide">
                                    <div className="team_detail wow slideInLeft">
                                        <div className="team_detail_inner">
                                        <img src="agency-studio/img/canjeo/01-USB.png" alt="Memoria USB 64GB" />
                                        </div>
                                        <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>10 PanPoints</a>
                                        <p className="default_text text-center open_sans">Memoria USB
                                        128 gb</p>
                                    </div>
                                    </div>

                                    {/* Segundo slide */}
                                    <div className="swiper-slide">
                                    <div className="team_detail wow slideInLeft">
                                        <div className="team_detail_inner">
                                        <img src="agency-studio/img/canjeo/04-selfie-stick.png" alt="Selfie Stick" />
                                        </div>
                                        <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>30 PanPoints</a>
                                        <p className="default_text text-center open_sans">Selfie Stick</p>
                                    </div>
                                    </div>

                                    {/* Tercer slide */}
                                    <div className="swiper-slide">
                                    <div className="team_detail wow slideInLeft">
                                        <div className="team_detail_inner">
                                        <img src="agency-studio/img/canjeo/07-licuadora-personal.png" alt="Maleta portaherramientas" />
                                        </div>
                                        <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>40 PanPoints</a>
                                        <p className="default_text text-center open_sans">Licuadora
personal para
batidos </p>
                                    </div>
                                    </div>

                                    {/* Cuarto slide */}
                                    <div className="swiper-slide">
                                    <div className="team_detail wow slideInLeft">
                                        <div className="team_detail_inner">
                                        <img src="agency-studio/img/canjeo/10-sandwichera-antiadherente.png" alt="Máquina de mini donas" />
                                        </div>
                                        <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>50 PanPoints</a>
                                        <p className="default_text text-center open_sans">Sandwichera
Antiadherente
</p>
                                    </div>
                                    </div>

                                    <div className="swiper-slide">
                                        <div className="team_detail wow slideInLeft">
                                            <div className="team_detail_inner">
                                                <img src="agency-studio/img/canjeo/12-aspiradora-mano.png" alt="team_img" />
                                            </div>
                                            
                                                <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>55 PanPoints</a>
                                            <p className="default_text text-center open_sans">Aspiradora
inalámbrica de
mano
                                            </p>
                                            <ul className="team_members_list">
                                            </ul>
                                        </div>
                                    </div> 

                                    <div className="swiper-slide">
                                        <div className="team_detail wow slideInLeft">
                                            <div className="team_detail_inner">
                                                <img src="agency-studio/img/canjeo/27-bateria-de-cocina.png" alt="team_img" />
                                            </div>
                                            
                                                <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>150 PanPoints</a>
                                            <p className="default_text text-center open_sans">Batería de cocina
                                            antiadherente 
                                            </p>
                                            <ul className="team_members_list">
                                            </ul>
                                        </div>
                                    </div> 

                                    <div className="swiper-slide">
                                        <div className="team_detail wow slideInLeft">
                                            <div className="team_detail_inner">
                                                <img src="agency-studio/img/canjeo/30-barra-de-sonido.png" alt="team_img" />
                                            </div>
                                            
                                                <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>250 PanPoints</a>
                                            <p className="default_text text-center open_sans">Barra de sonido
                                            </p>
                                            <ul className="team_members_list">
                                            </ul>
                                        </div>
                                    </div> 

                                    <div className="swiper-slide">
                                        <div className="team_detail wow slideInLeft">
                                            <div className="team_detail_inner">
                                                <img src="agency-studio/img/canjeo/38-dron-dji-flip.png" alt="team_img" />
                                            </div>
                                            
                                                <a href="/catalogo" className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy" style={{marginTop: '10px', width: '190px', alignItems: 'center'}}>1000 PanPoints</a>
                                            <p className="default_text text-center open_sans">Dron Dji
                                            </p>
                                            <ul className="team_members_list">
                                            </ul>
                                        </div>
                                    </div> 
                                    {/* Puedes añadir más slides aquí */}
                                </Slider>
                                <a href="/catalogo" class="btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10" style={{lineheight: '24px'}}>Ver catálogo</a>
                            </div>

                        </div>

                    </div>
                </section>

                <section className="pricing_section big_padding bg_grey" id="entrenamientos" style={{paddingBottom:'0px'}}>

                    <div className="container">

                        <div className="pricing_table_section">
                            <p className="default_text raleway text-center default_small_heading blue_color font_200">Nuestros</p>

                            <h2 className="default_section_heading text-center">
                
                                            <span className="font_200">
                
                                            Webinars
                
                                            </span>
                            </h2>
                            
                            <hr className="default_divider default_divider_blue default_divider_big" />

                            {/* <Webinar2024 /> */}

                            <br/>
                            {/* <p className="default_text text-center open_sans default_text_light" style={{fontSize: '17px', paddingTop: '75px'}}>
                                <strong>Este programa busca premiar a los participantes que se actualizan constantemente en nuestras soluciones. </strong>

                                Por tal motivo, si al menos dos colaboradores de la compañía participante se registra y asiste a cada webinar mensual, con un mínimo de asistencia de <strong>30 minutos</strong>, se le otorgará a la compañía un total de <strong style={{color: '#0085AD'}}>25 puntos</strong>.
                                
                                <br/><br/>
                                
                                Es importante, que al momento de realizar su registro, el participante utilice el <strong>correo corporativo o registrado</strong> en el programa para identificar el dominio y asignar el puntaje mencionado de forma correcta.
                            </p> */}

                        </div>

                    </div>

                </section>

                <section class="pricing_section bg_grey" id="entrenamientos" style={{paddingBottom:'80px'}}>
                    <div class="container">
                        <div class="pricing_table_section">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div>
                                        <a href="/webinars-2023" target="_blank">
                                            <img src="/agency-studio/img/entrenamientos-be-focused-panduit-2023.jpg" alt="" style={{width: '100%'}} />
                                        </a>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div>
                                        <a href="/webinars-2024" target="_blank">
                                            <img src="/agency-studio/img/entrenamientos-Be-focused-panduit-2024.jpg" alt="" style={{width: '100%'}} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Entrenamientos Panduit Week */}
                {/* <section class="blog_section big_padding" id="entrenamientos" style={{paddingtop: '0px', paddingbottom: '40px'}}>

                    <div class="container">

                        <h2 class="default_section_heading text-center">

                        <span class="font_200" style={{marginRight:'5px'}}> Entrenamientos</span> Panduit <span class="font_200">Week  </span>

                        </h2>

                        <hr class="default_divider default_divider_blue default_divider_big" />

                            <div class="blog_slider_main swiper-container">

                                    <PanduitWeek2024 />

                            </div>

                    </div>

                    </section> */}

                <div className="swiper-pagination"></div>
            </section>

                <section id="contacto" className="big_padding bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main-title wow fadeIn" data-wow-delay="300ms">
                                    <h5> ¿Necesita asistencia?</h5>
                                    <h2> Contáctenos </h2>
                                    <hr className="default_divider default_divider_blue" />
                                    
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 d-flex flex-column justify-content-start align-item-center contact_address heading_space wow fadeInLeft" data-wow-delay="400ms">
                                <div className="contact-table my-3">
                                    <div className="d-table-cell cells">
                                        <span className="icon-cell"><i className="fas fa-envelope"></i></span>
                                    </div>
                                    <div className="d-table-cell cells">
                                        <h4 className="color-black m-0">Correo</h4>
                                        <p><a href="mailto:info@panduitlatam.com" className="para-color">info@panduitlatam.com</a> </p>
                                    </div>
                                </div>
                                <div className="contact-table my-3">
                                    <div className="d-table-cell cells">
                                        <span className="icon-cell"><i className="fas fa-globe-americas"></i></span>
                                    </div>
                                    <div className="d-table-cell cells">
                                        <h4 className="color-black m-0">Conozca más</h4>
                                        <p><a href="https://www.panduit.com/latam/es/home.html" className="para-color">panduit.com</a> </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 heading_space wow fadeInRight" data-wow-delay="450ms">

                                <p>Este programa es bajo invitación. para cualquier duda o información sobre su puntaje, entrenamientos y más no dude en escribirnos.                 </p>

                                <div className="col-md-12 text-right" >
                                    <a type="button" href="/registro" className="btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10" id="btn_submit" style={{fontweight: 'bold', color: 'white'}}>Registro</a>
                                </div>

                            
                            </div>
                        </div>
                    </div>
                </section>

            
        </div>
    );
}

export default Home