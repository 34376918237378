import React from 'react'
import { HeaderDos } from '../widgets/Widgets'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function Webinar2024() {
    const webinars = [
        {
            name: "Rodolfo Neira",
            date: "Jueves 10 de Octubre de 2024",
            time: "10:00 hrs.",
            title: "Respaldo de Energía Crítica para ambientes Enterprise",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/c845b1f7-f65f-f150-9c13-191b1ca3dfb5.jpg",
            link: "https://www.youtube.com/watch?v=yS3-Ic0Jydo",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        },
        {
            name: "Víctor Juárez",
            date: "Jueves 5 de septiembre de 2024",
            time: "10:00 hrs.",
            title: "Aplicaciones de fibra óptica para alta velocidad en centros de datos",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/78dbf6c9-960a-ba8b-e1e7-b5a0eee8553a.jpg",
            link: "https://youtu.be/lUP6SgnxCiA",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        },
        {
            name: "Juan Pablo Borray",
            date: "Jueves 8 de Agosto de 2024",
            time: "10:00 hrs.",
            title: "Novedades de Fibra Óptica",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/9ea1944d-b24c-bd95-9436-b682943c78fc.jpg",
            link: "https://youtu.be/RLFHm9iNOBY",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        },
        {
            name: "Luis Aguirre y Bob Krisel",
            date: "Jueves 6 de Junio de 2024",
            time: "10:00 hrs.",
            title: "Abrazaderas de Cable: Edición Actualizada",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/45c69848-4559-ee51-bfed-864467e49234.gif",
            link: "https://www.youtube.com/watch?v=dnZOyEYb9PI&feature=youtu.be",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        },
        {
            name: "Raúl Alan Prieto",
            date: "Jueves 9 de Mayo de 2024",
            time: "10:00 hrs.",
            title: "Evolución de la Fibra Óptica: Alternativas para Infraestructuras del Futuro",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/ac5764f0-1a1a-1e71-4dc7-ba32e24c6b3b.jpg",
            link: "https://www.youtube.com/watch?v=w1OX2UaVs4w",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        },
    ];

    return (
        <div className="row">
            {webinars.map((webinar, index) => (
                <div className="col-sm-12 col-md-6 col-lg-4 mb-4" key={index}>
                <div className="swiper-slide">
                    <div className="blog_slide wow slideInLeft">
                    <a href="#" className="anchor_style_default">
                        <h3 className="small_heading_navy raleway center_on_mobile font_400">
                        Imparte: {webinar.name}
                        </h3>
                    </a>
                    <p className="default_text default_text_light open_sans center_on_mobile">
                        {webinar.date} | <br /> {webinar.time}
                    </p>
                    <div className="blog_img">
                        <a href={webinar.link} target="_blank" rel="noopener noreferrer">
                        <img src={webinar.image} alt="blog_img" />
                        </a>
                    </div>
                    <p
                        className="default_text open_sans center_on_mobile"
                        style={{ color: "#0085AD", fontWeight: "bold", fontSize: "14px" }}
                    >
                        {webinar.title} <br />
                    </p>
                    <a
                        href={webinar.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`btn ${webinar.buttonColor} btn-rounded btn-large`}
                        style={{ width: "300px", marginTop: "15px" }}
                    >
                        <span style={{ fontSize: "22px" }}>▸</span>
                        {webinar.buttonText}
                    </a>
                    </div>
                </div>
                </div>
            ))}
        </div>
    )
}

export function PanduitWeek2024(){
    const webinars = [
        {
            name: "Juan Pablo Borray y Mauricio Escobar",
            date: "Martes 16 de julio 2024",
            time: "México UTC/GMT -6 CDT",
            title: "Edificios Inteligentes/AV",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/f1a00ef1-dfe8-fb03-32e6-ccb81925fcc5.jpg",
            link: "https://youtu.be/hdrzUSYpa00",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        },
        {
            name: "Jorge Snadoval y Edgar Arzaluz",
            date: "Miércoles 17 de julio 2024",
            time: "México UTC/GMT -6 CDT",
            title: "Industrial/Retail",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/04eabf7e-21d7-49e4-9ac0-e4eb768ba895.jpg",
            link: "https://youtu.be/RSecg834YdU",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        },
        {
            name: "Victor Juarez y Juan Medina",
            date: "Jueves 18 de julio 2024",
            time: "México UTC/GMT -6 CDT",
            title: "Centro de Datos/ Eléctrico",
            image: "https://mcusercontent.com/9286884ec8991edd420ae584b/images/d40430fc-9e5e-d8f6-d4b4-5640c62cce40.jpg",
            link: "https://youtu.be/g8gPQiGLwbA",
            buttonText: "Ver grabación",
            buttonColor: "btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10",
        }
    ];
    
    return (
        <div className="row">
            {webinars.map((webinar, index) => (
                <div className="col-sm-12 col-md-6 col-lg-4 mb-4" key={index}>
                <div className="swiper-slide">
                    <div className="blog_slide wow slideInLeft">
                    <a href="#" className="anchor_style_default">
                        <h3 className="small_heading_navy raleway center_on_mobile font_400">
                        Imparte: {webinar.name}
                        </h3>
                    </a>
                    <p className="default_text default_text_light open_sans center_on_mobile">
                        {webinar.date} | <br /> {webinar.time}
                    </p>
                    <div className="blog_img">
                        <a href={webinar.link} target="_blank" rel="noopener noreferrer">
                        <img src={webinar.image} alt="blog_img" />
                        </a>
                    </div>
                    <p
                        className="default_text open_sans center_on_mobile"
                        style={{ color: "#0085AD", fontWeight: "bold", fontSize: "14px" }}
                    >
                        {webinar.title} <br />
                    </p>
                    <a
                        href={webinar.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`btn ${webinar.buttonColor} btn-rounded btn-large`}
                        style={{ width: "300px", marginTop: "15px" }}
                    >
                        <span style={{ fontSize: "22px" }}>▸</span>
                        {webinar.buttonText}
                    </a>
                    </div>
                </div>
                </div>
            ))}
        </div>
    )
}

export function Webinar2023() {
    const settings = {
        dots: false,             // Muestra puntos de navegación
        infinite: true,         // Desplazamiento infinito
        speed: 500,             // Velocidad del scroll
        slidesToShow: 3,        // Número de elementos visibles
        slidesToScroll: 1,      // Desplazar un slide a la vez
        autoplay: true,         // Habilita el desplazamiento automático
        autoplaySpeed: 2000,    // Velocidad del desplazamiento automático (ms)
        responsive: [           // Responsividad para que funcione en diferentes tamaños de pantalla
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <HeaderDos />
            <section className="pricing_section big_padding bg_grey" id="entrenamientos" style={{marginTop: '100px'}}>

                <div className="container">

                    <div className="pricing_table_section">
                        <p className="default_text raleway text-center default_small_heading blue_color font_200">Webinar's</p>

                        <h2 className="default_section_heading text-center">

                                        <span className="font_200"> Nuestros</span> Entrenamientos

                        </h2>

                        <hr className="default_divider default_divider_blue default_divider_big" />

                        <div className="row">

                            <div className="col-sm-4">

                                <div className="swiper-slide">
                                    <div className="blog_slide wow slideInLeft">
                                        <a href="#" className="anchor_style_default">
                                            <h3 className="small_heading_navy raleway center_on_mobile font_400" >Imparte: Manuel Pineda</h3>
                                        </a>
                                        <p className="default_text default_text_light open_sans center_on_mobile">Jueves 9 de Noviembre de 2023 | <br /> 10:00 hrs.
                                            </p>
                                        <div className="blog_img" > <a href="https://us02web.zoom.us/webinar/register/WN_zwzznDD_SQKNhIGcBBOgfA#/registration"  target="_blank"><img src="agency-studio/img/webinars/soluciones-de-colaboracion-wave-atlona-webinar-panduit.png" alt="blog_img" /></a>
                                        </div>
                                        <p className="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontWeight: 'bold', fontSize: '14px'}}>
                                        Soluciones de colaboración con WAVE - Atlona
                                        </p>
                                        <a href="#" className="btn btn-yellow-black btn-rounded btn-large" style={{width: '300px', marginTop: '10px'}}>Ver grabación</a>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-4">

                                <div className="swiper-slide">
                                    <div className="blog_slide wow slideInLeft">
                                        <a href="#" className="anchor_style_default">
                                            <h3 className="small_heading_navy raleway center_on_mobile font_400" >Imparte: Victor Medina</h3>
                                        </a>
                                        <p className="default_text default_text_light open_sans center_on_mobile">Jueves 12 de Octubre 2023 | <br /> 10:00 hrs.
                                            </p>
                                        <div className="blog_img" > <a href="https://youtu.be/gJR7rgMwSQg"  target="_blank"><img src="agency-studio/img/webinars/gestion-integral-de-data-centers-con-software-DCIM-webinar-panduit.png" alt="blog_img" /></a>
                                        </div>
                                        <p className="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontWeight: 'bold', fontSize: '14px'}}>
                                            Gestión Integral de Data Centers con Sotware DCIM
                                        </p>
                                        <a href="https://youtu.be/gJR7rgMwSQg"  target="_blank" className="btn btn-yellow-black btn-rounded btn-large" style={{width: '300px', marginTop: '10px'}}>Ver grabación</a>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-4">

                                <div className="swiper-slide">

                                    <div className="blog_slide wow fadeInDownBig">

                                        <a href="#" className="anchor_style_default">

                                            <h3 className="small_heading_navy raleway center_on_mobile font_400" >Imparte: César Macías</h3>

                                        </a>

                                        <p className="default_text default_text_light open_sans center_on_mobile">Jueves 14 de Septiembre 2023 | México UTC/GMT -6 CDT</p>
                                        
                                        <div className="blog_img"> <a href="https://www.youtube.com/watch?v=7S_POkEHIqA&feature=youtu.be"  target="_blank"><img src="agency-studio/img/webinars/tecnicas-de-zonificacion-en-infraestructura-fisica-webinar-panduit.png" alt="blog_img" /></a>

                                        </div>

                                    <p className="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontWeight: 'bold', fontSize: '14px'}}>
                                        Técnicas de zonificación en infraestructura física
                                    </p>
                                    
                                    <a href="https://youtu.be/7S_POkEHIqA"  target="_blank" className="btn btn-yellow-black btn-rounded btn-large" style={{width: '300px', marginTop: '10px'}}>Ver grabación</a>

                                    </div>

                                </div>

                            </div>

                            <div className="col-sm-4">

                                <div className="swiper-slide" style={{paddingTop: '30px'}}>

                                    <div className="blog_slide wow fadeInDownBig">

                                        <a href="#" className="anchor_style_default">

                                            <h3 className="small_heading_navy raleway center_on_mobile font_400" >Imparte: David Aguayo</h3>

                                        </a>

                                        <p className="default_text default_text_light open_sans center_on_mobile">Lunes 3 de Julio 2023 | <br /> 10:00 hrs. CDT </p>
                                    
                                        
                                        <div className="blog_img"> <a href="https://www.youtube.com/watch?v=6Znr5MMaGFw"  target="_blank"><img src="agency-studio/img/webinars/soluciones-de-identificacion-impresoras-etiquetas-mas-webinar-panduit-latam.png" alt="blog_img" /></a>

                                        </div>

                                    <p className="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontWeight: 'bold', fontSize: '14px'}}>
                                            Soluciones de Identificación <br /> Panduit
                                    </p>
                                    <a href="https://www.youtube.com/watch?v=6Znr5MMaGFw"  target="_blank" className="btn btn-yellow-black btn-rounded btn-large" style={{width: '300px', marginTop: '10px'}}>Ver grabación</a>

                                    </div>

                                </div>

                            </div>

                            <div className="col-sm-4">

                                <div className="swiper-slide" style={{paddingTop: '30px'}}>
                                    <div className="blog_slide wow slideInLeft">
                                        <a href="#" className="anchor_style_default">
                                            <h3 className="small_heading_navy raleway center_on_mobile font_400" >Imparte: Vladimir Linares</h3>
                                        </a>
                                        <p className="default_text default_text_light open_sans center_on_mobile">Jueves 15 de Junio 2023 | <br /> 10:00 hrs. CDT
                                            </p>
                                        <div className="blog_img" > <a href="https://youtu.be/-KYATE1W82A"  target="_blank"><img src="agency-studio/img/webinars/administracion-de-cableado-automatizado-Rapid-ID-webinar-panduit.png" alt="blog_img" /></a>
                                        </div>
                                        <p className="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontWeight: 'bold', fontSize: '14px'}}>
                                            Soluciones de Optimización: <em>Rapid</em> <br /> ID™
                                        </p>
                                        <a href="https://youtu.be/-KYATE1W82A"  target="_blank" className="btn btn-yellow-black btn-rounded btn-large" style={{width: '300px', marginTop: '10px'}}>Ver grabación</a>
                                    </div>
                                </div>

                            </div>

                        
                        </div>

                    </div>

                </div>

            </section>

            <section class="blog_section big_padding" id="entrenamientos" style={{paddingtop: '0px'}}>

                <div class="container">
                    <h2 class="default_section_heading text-center">
                        <span class="font_200">
                            Entrenamientos
                        </span>
                        Panduit 
                        <span class="font_200">
                        Week 
                        </span>
                    </h2>
                    <hr class="default_divider default_divider_blue default_divider_big" />
                        <div className="blog_slider_main swiper-container">
                            <Slider {...settings}>
                                <div class="swiper-slide">

                                    <div class="blog_slide wow fadeInDownBig">

                                        <a href="blog.html" class="anchor_style_default">

                                            <h3 class="small_heading_navy raleway center_on_mobile font_400">Imparte: Victor <br /> Juárez</h3>

                                        </a>

                                        <p class="default_text default_text_light open_sans center_on_mobile">Lunes 14 Agosto 2023 | México UTC/GMT -6 CDT</p>

                                        <div class="blog_img"> <a  target="_blank" href="https://youtu.be/1KiY67iZ6QU"><img src="https://mcusercontent.com/9286884ec8991edd420ae584b/images/619d3854-ffa5-7da0-7c3e-9369b7fc0bd3.jpg" alt="blog_img"/></a>

                                        </div>

                                    <p class="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontweight: 'bold', fontsize: '14px'}}>
                                        Centros de Datos
                                    </p>
                                        
                                    <a  target="_blank" href="https://youtu.be/1KiY67iZ6QU" class="btn btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10 btn-rounded btn-large open_sans bg_before_navy">Ver grabación</a>

                                    </div>

                                </div>

                                <div class="swiper-slide">

                                    <div class="blog_slide wow fadeInDownBig">

                                        <a href="blog.html" class="anchor_style_default">

                                            <h3 class="small_heading_navy raleway center_on_mobile font_400">Imparte: Mauricio Escobar & Manuel Pineda</h3>

                                        </a>

                                        <p class="default_text default_text_light open_sans center_on_mobile">Martes 15 2023 | México UTC/GMT -6 CDT</p>

                                        <div class="blog_img"> <a  target="_blank" href="https://youtu.be/63AOqEAkQA4"><img src="https://mcusercontent.com/9286884ec8991edd420ae584b/images/b6978c5c-62f9-530c-ee1c-27d9e7c742e7.gif" alt="blog_img"/></a>

                                        </div>

                                    <p class="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontweight: 'bold', fontsize: '14px'}}>
                                        Audio y Video
                                    </p>
                                        
                                    <a  target="_blank" href="https://youtu.be/63AOqEAkQA4" class="btn btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10 btn-rounded btn-large open_sans bg_before_navy">Ver grabación</a>

                                    </div>

                                </div>

                                <div class="swiper-slide">

                                    <div class="blog_slide wow fadeInDownBig">

                                        <a href="blog.html" class="anchor_style_default">

                                            <h3 class="small_heading_navy raleway center_on_mobile font_400">Imparte: Juan Pablo <br /> Borray</h3>

                                        </a>

                                        <p class="default_text default_text_light open_sans center_on_mobile">Miercoles 16 2023 | México UTC/GMT -6 CDT</p>

                                        <div class="blog_img"> <a  target="_blank" href="https://youtu.be/-wIAnc-qnj8"><img src="https://mcusercontent.com/9286884ec8991edd420ae584b/images/6d0b61e9-f170-bd72-7f11-94eb2698f254.jpg" alt="blog_img"/></a>

                                        </div>

                                    <p class="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontweight: 'bold', fontsize: '14px'}}>
                                        Redes Empresariales
                                    </p>
                                        
                                    <a  target="_blank" href="https://youtu.be/-wIAnc-qnj8" class="btn btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10 btn-rounded btn-large open_sans bg_before_navy">Ver grabación</a>

                                    </div>

                                </div>

                                <div class="swiper-slide">

                                    <div class="blog_slide wow fadeInDownBig">

                                        <a href="blog.html" class="anchor_style_default">

                                            <h3 class="small_heading_navy raleway center_on_mobile font_400">Imparte: Edgar Arzaluz & Alan Prieto</h3>

                                        </a>

                                        <p class="default_text default_text_light open_sans center_on_mobile">Jueves 17 2023 | México UTC/GMT -6 CDT</p>

                                        <div class="blog_img"> <a  target="_blank" href="https://youtu.be/t8WJVDbh5W8"><img src="https://mcusercontent.com/9286884ec8991edd420ae584b/images/52c4dd60-01a4-9dc7-5473-43162971dd46.gif" alt="blog_img"/></a>

                                        </div>

                                    <p class="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontweight: 'bold', fontsize: '14px'}}>
                                        Redes Industriales
                                    </p>
                                        
                                    <a  target="_blank" href="https://youtu.be/t8WJVDbh5W8" class="btn btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10 btn-rounded btn-large open_sans bg_before_navy">Ver grabación</a>

                                    </div>

                                </div>
                                
                                <div class="swiper-slide">

                                    <div class="blog_slide wow fadeInDownBig">

                                        <a href="blog.html" class="anchor_style_default">

                                            <h3 class="small_heading_navy raleway center_on_mobile font_400">Imparte: Juan <br /> Medina</h3>

                                        </a>

                                        <p class="default_text default_text_light open_sans center_on_mobile">Viernes 18 2023 | México UTC/GMT -6 CDT</p>

                                        <div class="blog_img"> <a  target="_blank" href="https://youtu.be/iC4xlHHMNUM"><img src="https://mcusercontent.com/9286884ec8991edd420ae584b/images/a443c751-0d92-e6c0-a43d-8dbb857ef57b.jpg" alt="blog_img"/></a>

                                        </div>

                                    <p class="default_text open_sans center_on_mobile" style={{color: '#0085AD', fontweight: 'bold', fontsize: '14px'}}>
                                        Soluciones Electricas
                                    </p>
                                        
                                    <a  target="_blank" href="https://youtu.be/iC4xlHHMNUM" class="btn btn btn-yellow-black btn-rounded btn-large open_sans bg_before_navy mt-10 btn-rounded btn-large open_sans bg_before_navy">Ver grabación</a>

                                    </div>

                                </div>
                            </Slider>
                        </div>
                </div>
            </section>
        </div>
    )
}



export default Webinar2023