import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2"; 
import { HeaderDos } from '../widgets/Widgets';
import { FaHome, FaSignInAlt } from 'react-icons/fa';

function Paginas() {
    return (
        <div>Paginas</div>
    );
}

export function Catalogo() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        // Hacer la solicitud al backend para obtener los productos
        fetch('https://system-befocused-production.up.railway.app/productos')
            .then((response) => response.json())
            .then((data) => {
                setProductos(data.productos);
            })
            .catch((error) => {
                console.error('Error al obtener los productos:', error);
            });
    }, []);

    return (
        <div>
            <HeaderDos />
            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="row">
                                {/* Mapeamos los productos obtenidos de la base de datos */}
                                {productos.map((producto) => (
                                    <div className="slide col-4" key={producto.PROD_ID}>
                                        <div className="team_detail wow slideInLeft team_item">
                                            <div className="team_detail_inner">
                                                <img
                                                    src={`agency-studio/img/canjeo/${producto.PROD_ruta_imagen}.png`}
                                                    alt={producto.PROD_nombre}
                                                />
                                            </div>
                                            <a
                                                href="#"
                                                className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy"
                                                style={{
                                                    marginTop: '10px',
                                                    width: '190px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {Math.floor(producto.PROD_total)} PanPoints
                                            </a>
                                            <p className="default_text text-center open_sans">
                                                {producto.PROD_nombre}
                                            </p>
                                            <ul className="team_members_list"></ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export function CatalogoCanje() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        // Hacer la solicitud al backend para obtener los productos
        fetch('https://system-befocused-production.up.railway.app/productos')
            .then((response) => response.json())
            .then((data) => {
                setProductos(data.productos);
            })
            .catch((error) => {
                console.error('Error al obtener los productos:', error);
            });
    }, []);

    return (
        <div>
            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="row">
                                {/* Mapeamos los productos obtenidos de la base de datos */}
                                {productos.map((producto) => (
                                    <div className="slide col-4" key={producto.PROD_ID}>
                                        <div className="team_detail wow slideInLeft team_item">
                                            <div className="team_detail_inner">
                                                <img
                                                    src={`/agency-studio/img/canjeo/${producto.PROD_ruta_imagen}.png`}
                                                    alt={producto.PROD_nombre}
                                                />
                                            </div>
                                            <a
                                                href="/login"
                                                className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy"
                                                style={{
                                                    marginTop: '10px',
                                                    width: '190px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {Math.floor(producto.PROD_total)} PanPoints
                                            </a>
                                            <p className="default_text text-center open_sans">
                                                {producto.PROD_nombre}
                                            </p>
                                            <ul className="team_members_list"></ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export function CatalogoCanjeError() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        // Hacer la solicitud al backend para obtener los productos
        fetch('https://system-befocused-production.up.railway.app/productos')
            .then((response) => response.json())
            .then((data) => {
                setProductos(data.productos);
            })
            .catch((error) => {
                console.error('Error al obtener los productos:', error);
            });
    }, []);

    return (
        <div>
            <header className="site-header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
                <nav className="navbar navbar-expand-lg nav-bottom-line center-brand static-nav" style={{ background: 'white' }}>
                    <div className="container d-flex align-items-center justify-content-between">
                        <a className="navbar-brand d-flex align-items-center" href="/" style={{ position: 'relative' }}>
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-default" />
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button" data-toggle="collapse" data-target="#xenav">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="xenav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" style={{ color: '#000' }} href="/">
                                    <FaHome style={{ marginRight: '8px' }} /> Ir al Inicio
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" style={{ color: '#000' }} href="/login">
                                    <FaSignInAlt style={{ marginRight: '8px' }} /> Iniciar sesión
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="row">
                                {/* Mapeamos los productos obtenidos de la base de datos */}
                                {productos.map((producto) => (
                                    <div className="slide col-4" key={producto.PROD_ID}>
                                        <div className="team_detail wow slideInLeft team_item">
                                            <div className="team_detail_inner">
                                                <img
                                                    src={`/agency-studio/img/canjeo/${producto.PROD_ruta_imagen}.png`}
                                                    alt={producto.PROD_nombre}
                                                />
                                            </div>
                                            <a
                                                href="/login"
                                                className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy"
                                                style={{
                                                    marginTop: '10px',
                                                    width: '190px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {Math.floor(producto.PROD_total)} PanPoints
                                            </a>
                                            <p className="default_text text-center open_sans">
                                                {producto.PROD_nombre}
                                            </p>
                                            <ul className="team_members_list"></ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export function Registro() {

    const [formData, setFormData] = useState({
        nombre: "",
        apellido: "",
        compania: "",
        USU_cargo: "",
        USU_cargo_otro: "",
        telefono: "",
        email: "",
        notas: ""
    });

    const [otroCargoVisible, setOtroCargoVisible] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleCargoChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, USU_cargo: value });
        setOtroCargoVisible(value === "otro");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("https://system-befocused-production.up.railway.app/registro", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Registro exitoso",
                    text: data.message,
                });
                setFormData({
                    nombre: "",
                    apellido: "",
                    compania: "",
                    USU_cargo: "",
                    USU_cargo_otro: "",
                    telefono: "",
                    email: "",
                    notas: "",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error en el registro",
                    text: data.message,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un problema al conectar con el servidor",
            });
        }
    };

    return (
        <div>
            <HeaderDos />

            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="team_item col-md-12 d-flex flex-column justify-content-start" style={{ padding: '20px 50px 20px 50px', background:'#fff', textAlign:'left' }}>
                                    <h3 class="default_section_heading raleway navy_blue mt-10 mb-20">Registro  <span class="font_200"> Be Focused 2025 </span></h3>
                                    <img className='image-login' src="/agency-studio/img/banner-formulario-BF-2025.jpg" alt="" />
                                    <p className='mb-10 mt-10'>Con la finalidad de mejorar nuestra comunicación durante el programa, deseamos conocer los datos de contacto de quienes intervienen y participan más activamente en Be Focused.</p>
                                    <p className='mb-10'>La asistencia del personal comercial al webinar otorga PanPoints.</p>
                                    <p>¡Gracias por su apoyo!</p>
                                    <form className="" onSubmit={handleSubmit}>
                                        {/* Nombre */}
                                        <label htmlFor="Nombre" className="ml-2 block text-sm">
                                            Nombre <strong style={{color:'#007bff'}}>*</strong>
                                        </label>
                                        <div className="form-address">
                                            <input 
                                                type="text" 
                                                id="nombre" 
                                                name="nombre" 
                                                placeholder='Ingresa tu nombre'
                                                className="form-control" 
                                                value={formData.nombre} 
                                                onChange={handleChange}
                                                required 
                                            />
                                        </div>
                                        
                                        {/* Apellido */}
                                        <label htmlFor="Apellido" className="ml-2 block text-sm">
                                            Apellido <strong style={{color:'#007bff'}}>*</strong>
                                        </label>
                                        <div className="form-address">
                                            <input 
                                                type="text" 
                                                id="apellido" 
                                                name="apellido" 
                                                className="form-control" 
                                                placeholder='Ingresa tu apellido'
                                                value={formData.apellido} 
                                                onChange={handleChange}
                                                required 
                                            />
                                        </div>

                                        {/* Compañia */}
                                        <label htmlFor="Compañia" className="ml-2 block text-sm">
                                            Compañia <strong style={{color:'#007bff'}}>*</strong>
                                        </label>
                                        <div className="form-address">
                                            <input 
                                                type="text" 
                                                id="compania" 
                                                name="compania" 
                                                className="form-control" 
                                                placeholder='Ingrese su compañia'
                                                value={formData.compania} 
                                                onChange={handleChange} 
                                                required 
                                            />
                                        </div>

                                        {/* Cargo */}
                                        <label htmlFor="Cargo" className="ml-2 block text-sm">
                                            Cargo <strong style={{color:'#007bff'}}>*</strong>
                                        </label>
                                        <div className="form-group">
                                            <div className="flex flex-col gap-3">
                                                {/* Opciones de radio */}
                                                <div className="form-check flex items-center">
                                                    <input 
                                                        type="radio" 
                                                        id="cargo1" 
                                                        name="USU_cargo" 
                                                        value="Estoy en ventas y asisto a los entrenamientos"
                                                        className="form-radio h-4 w-4 text-primary border-gray-300 focus:ring-primary mb-0"
                                                        checked={formData.USU_cargo === "Estoy en ventas y asisto a los entrenamientos"}
                                                        onChange={handleCargoChange}
                                                    />
                                                    <label htmlFor="cargo1" className="ml-2 block text-sm text-gray-700">
                                                        Estoy en ventas y asisto a los entrenamientos
                                                    </label>
                                                </div>

                                                <div className="form-check flex items-center">
                                                    <input 
                                                        type="radio" 
                                                        id="cargo2" 
                                                        name="USU_cargo" 
                                                        value="Tengo un rol administrativo, y me encargo de la redención de puntos por premios"
                                                        className="form-radio h-4 w-4 text-primary border-gray-300 focus:ring-primary mb-0"
                                                        checked={formData.USU_cargo === "Tengo un rol administrativo, y me encargo de la redención de puntos por premios"}
                                                        onChange={handleCargoChange}
                                                    />
                                                    <label htmlFor="cargo2" className="ml-2 block text-sm text-gray-700">
                                                        Tengo un rol administrativo, y me encargo de la redención de puntos por premios
                                                    </label>
                                                </div>

                                                <div className="form-check flex items-center">
                                                    <input 
                                                        type="radio" 
                                                        id="cargo3" 
                                                        name="USU_cargo" 
                                                        value="Tengo un rol de dirección o gerencial en la compañía y decido respecto a la participación de mi equipo en los entrenamientos y la redención de premios"
                                                        className="form-radio h-4 w-4 text-primary border-gray-300 focus:ring-primary mb-0"
                                                        checked={formData.USU_cargo === "Tengo un rol de dirección o gerencial en la compañía y decido respecto a la participación de mi equipo en los entrenamientos y la redención de premios"}
                                                        onChange={handleCargoChange}
                                                    />
                                                    <label htmlFor="cargo3" className="ml-2 block text-sm text-gray-700">
                                                        Tengo un rol de dirección o gerencial en la compañía y decido respecto a la participación de mi equipo en los entrenamientos y la redención de premios
                                                    </label>
                                                </div>

                                                {/* Opción "Otro" */}
                                                <div className="form-check flex items-center">
                                                    <input 
                                                        type="radio" 
                                                        id="cargo4" 
                                                        name="USU_cargo" 
                                                        value="otro"
                                                        className="form-radio h-4 w-4 text-primary border-gray-300 focus:ring-primary mb-0"
                                                        checked={formData.USU_cargo === "otro"}
                                                        onChange={handleCargoChange}
                                                    />
                                                    <label htmlFor="cargo4" className="ml-2 block text-sm text-gray-700">
                                                        Otro
                                                    </label>
                                                </div>

                                                {/* Input de "Otro" (visible solo si se selecciona) */}
                                                {otroCargoVisible && (
                                                    <div className="form-address ml-6 mt-1">
                                                        <input 
                                                            type="text" 
                                                            id="cargo_otro" 
                                                            name="USU_cargo_otro" 
                                                            placeholder="Especifique su cargo" 
                                                            className="form-control"
                                                            value={formData.USU_cargo_otro}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Teléfono */}
                                        <label htmlFor="Teléfono" className="ml-2 block text-sm">
                                                Teléfono <strong style={{color:'#007bff'}}>*</strong>
                                            </label>
                                        <div className="form-address">
                                            <input 
                                                type="text" 
                                                id="telefono" 
                                                name="telefono" 
                                                className="form-control" 
                                                inputMode="numeric" 
                                                pattern="\d{10}" 
                                                maxLength="10" 
                                                placeholder='ingrese su número de teléfono'
                                                value={formData.telefono} 
                                                onChange={handleChange}
                                                required 
                                            />
                                        </div>

                                        {/* Email */}
                                        <label htmlFor="Coreo electrónico" className="ml-2 block text-sm">
                                            Correo electrónico corporativo (No se permite el uso de cuentas comerciales como Gmail, Hotmail, etc.) <strong style={{color:'#007bff'}}>*</strong>
                                            </label>
                                        <div className="form-address">
                                            <input 
                                                type="email" 
                                                id="email" 
                                                name="email" 
                                                className="form-control" 
                                                placeholder='Ingrese su correo electrónico'
                                                value={formData.email} 
                                                onChange={handleChange}
                                                required 
                                            />
                                        </div>

                                        {/* Notas */}
                                        <label htmlFor="" className="ml-2 block text-sm">
                                        ¿A quiénes de su compañía debe llegar la invitación de los entrenamientos mensuales? Escriba sus mails por favor.
                                        </label>
                                        <div className="form-address">
                                            <textarea 
                                                id="notas" 
                                                name="notas" 
                                                className="form-control" 
                                                rows="3"
                                                placeholder='(Opcional)'
                                                value={formData.notas} 
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>

                                        {/* Botón de envío */}
                                        <div className="form-address text-center" style={{ marginTop: '30px' }}>
                                            <button 
                                                type="submit" 
                                                className="btn btn-yellow-black btn-large btn-rounded contact_btn"
                                            >
                                                Enviar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default Paginas;